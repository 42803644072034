<template>
  <section>
    <div class="field is-hidden-mobile">
      <p>
        <span>Select your franchise &nbsp;</span>
        <span class="has-text-soft has-text-weight-semibold">*</span>
      </p>
    </div>
    <div class="field">
      <MultiSelect
        v-model="selected"
        :options="vmcAccounts"
        :show-labels="false"
        :close-on-select="true"
        :allow-empty="false"
        :group-select="true"
        track-by="id"
        placeholder="Select your franchise"
        :hide-selected="false"
        label="name"
      >
        <span slot="noResult">No franchises found</span>
      </MultiSelect>
    </div>
    <div class="field">
      <p>
        Select the method for inputting your Vehicle Mileage Check request
      </p>
    </div>
    <div class="columns">
      <div class="column is-6">
        <div class="box">
          <div class="columns is-centered">
            <img
              class="column is-narrow is-4-touch is-offset-4-touch"
              :src="require('core/img/icons/rapid-icon.svg')"
              width="150"
            />
          </div>
          <div class="content" :class="{ 'pb-5': $mq !== 'mobile' }">
            <p class="has-text-justified">
              Use this option as the quickest method for submission; only
              entering VRM and mileage.
            </p>
          </div>
          <div class="buttons is-centered">
            <button
              :disabled="!selected"
              class="button is-rounded is-soft"
              @click="$emit('confirm', { selected, type: 'rapid' })"
              :class="{ 'is-loading': $wait.is('rapidLoading') }"
            >
              Rapid Input
            </button>
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="box">
          <div class="columns is-centered">
            <img
              class="column is-narrow is-4-touch is-offset-4-touch"
              :src="require('core/img/icons/manual-icon.svg')"
              width="150"
            />
          </div>
          <div class="content">
            <p class="has-text-justified">
              Use this option if you have the vehicle documentation and would
              like to manually enter previous keeper details.
            </p>
          </div>
          <div class="buttons is-centered">
            <button
              :disabled="!selected"
              :class="{ 'is-loading': $wait.is('manualLoading') }"
              class="button is-rounded is-soft"
              @click="$emit('confirm', { selected, type: 'manual' })"
            >
              Manual Input
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'VmcOptions',
  components: { MultiSelect: () => import('vue-multiselect') },
  data: () => ({ selected: null }),
  watch: {
    selected() {
      this.$mxp.track('vmc_new_franchise_selected')
    }
  },
  computed: {
    ...mapGetters('auth', ['vmcAccounts'])
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="sass">
$cazana-blue: hsl(202, 100%, 37%)
.multiselect
  min-width: 16rem
  max-width: 100%
  .multiselect__option--selected
    background: #ccc
    color: #fff
    &.multiselect__option--highlight
      background: #ccc
      cursor: not-allowed
.multiselect__option
  &.multiselect__option--highlight
    background: $cazana-blue
</style>
